import styled from 'styled-components'

export const Details = styled.div`
  input {
    border: 2px solid blue;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    width: 80%;
    font-size: 15pt;

    @media (max-width: 576px) {
      font-size: 13pt;
    }
  }

  textarea {
    border: 2px solid blue;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    width: 80%;
    height: 10rem;
    font-size: 15pt;

    @media (max-width: 576px) {
      font-size: 13pt;
    }
  }

  button {
    border: none;
    border-radius: 8px;
    background: darkblue;
    color: white;
    padding: 0.5rem 1rem;
    cursor: pointer;

    &:hover {
      background: #08369c;
    }
  }

  width: 100%;
  text-align: center;

  p {
    font-size: 15pt;
  }

  @media (max-width: 960px) {
    h1 {
      margin-bottom: 2rem;
      font-size: 26pt;
      color: #212121;
    }

    h2 {
      font-size: 15pt;
    }

    p {
      margin-bottom: 1rem;
      font-size: 20pt;
      font-weight: normal;
      line-height: 1.3;
      color: ${({ theme }) => (theme === 'dark' ? 'white' : 'black')};

      @media (max-width: 576px) {
        font-size: 13pt;
      }
    }
  }
`

export const Wrapper = styled.div`
  padding: 4rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Message = styled.div`
  font-size: 15pt;
  background: green;
  color: white;
  border-radius: 10px;
  padding: 0.5rem 0.5rem;
  @media (max-width: 576px) {
    font-size: 12pt;
  }
`
