import transcriptionLady from 'assets/illustrations/transcription-lady.svg'
import { Button, Container } from 'components/common'
import { Header } from 'components/theme'
import { ThemeContext } from 'providers/ThemeProvider'
import React, { useContext } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Details, IntroWrapper, Thumbnail, Wrapper } from './styles'

export const Intro = () => {
  const { theme } = useContext(ThemeContext)

  return (
    <Wrapper>
      <Header fullHeader={true} showNavbarLinks={true} />
      <IntroWrapper as={Container}>
        <Details theme={theme}>
          <h1>100% Correct Transcription & Captioning</h1>
          <h4>Fast and Affordable</h4>
          <h4>
            <a
              href='/upload'
              style={{
                color: 'inherit',
                cursor: 'pointer',
                textDecoration: 'underline',
                fontWeight: '700',
              }}
            >
              Click here
            </a>{' '}
            to try our automated transcription service! No sign up required!
          </h4>
          <Button as={AnchorLink} href='#contact'>
            Contact Us
          </Button>
        </Details>
        <Thumbnail>
          <img src={transcriptionLady} alt='Lady transcribing meetings' />
        </Thumbnail>
      </IntroWrapper>
    </Wrapper>
  )
}
