import React, { useContext } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { ThemeContext } from 'providers/ThemeProvider'
import { Container, Button } from 'components/common'
import dev from 'assets/illustrations/skills.svg'
import { Wrapper, SkillsWrapper, Details, Thumbnail, AboutUs } from './styles'

export const Skills = () => {
  const { theme } = useContext(ThemeContext)

  return (
    <>
      <Wrapper id='about'>
        <SkillsWrapper as={Container}>
          <Thumbnail>
            <img src={dev} alt='Man showing charts and analytics' />
          </Thumbnail>
          <Details theme={theme}>
            <h1>We Serve:</h1>
            <ul>
              <li>Municipalities and Government Agencies</li>
              <li>Podcast Creators</li>
              <li>YouTube Channels</li>
              <li>Law Firms</li>
              <li>Insurance Firms</li>
              <li>Market Researchers</li>
            </ul>
            <Button as={AnchorLink} href='#contact'>
              Contact Us
            </Button>
          </Details>
        </SkillsWrapper>
      </Wrapper>
      <AboutUs>
        <hr></hr>
        <p>
          At Express Transcriber, we combine the most efficient machine learning
          algorithms and human editing to provide 100% correct transcription and
          captioning. Let’s face it, no machine learning model is capable of
          providing a 100% correct transcription. To provide a completely
          correct transcript, machine learning models need human supervision.
          Our transcribers are working along with the best Speech-to-Text AI to
          meet your transcribing and captioning needs quickly and reliably.
        </p>
        <hr></hr>
      </AboutUs>
    </>
  )
}
