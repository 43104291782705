import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    margin-top: 2rem;
  }
`

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0rem 1rem;
  margin: 0rem 4rem;
  background: #efeeff;
  width: 50%;
  border-radius: 10px;
  border: solid 2px;

  ul {
    list-style: none;
  }

  li {
    div {
      font-family: 'Courier New';
    }
    p {
      margin: 0rem;
      span {
        font-family: 'Roboto';
        font-weight: bold;
      }
    }
  }

  h3 {
    text-align: center;
    padding-top: 1rem;
  }

  @media (max-width: 768px) {
    padding: 0rem 2rem;
    margin: 0rem 0.5rem 1rem 0.5rem;
    width: 70%;
  }
`

export const FlexWrapper = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

export const BorderWrapper = styled.div`
  border: solid 1px;
`
