import styled from 'styled-components'
import detailsIllustration from 'assets/illustrations/details.svg'

export const Wrapper = styled.div`
  background-image: url(${detailsIllustration});
  background-size: contain;
  background-position: left top;
  background-repeat: no-repeat;
`

export const SkillsWrapper = styled.div`
  padding: 4rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`

export const Details = styled.div`
  flex: 1;
  padding-left: 2rem;

  @media (max-width: 960px) {
    padding-left: unset;
    width: 100%;
  }

  h1 {
    margin-bottom: 2rem;
    font-size: 26pt;
    color: ${({ theme }) => (theme === 'dark' ? '#fff' : '#212121')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) =>
        theme === 'light' ? 'unset' : 'difference'};
    }
  }

  p {
    margin-bottom: 2.5rem;
    font-size: 20pt;
    font-weight: normal;
    line-height: 1.3;
    color: ${({ theme }) => (theme === 'dark' ? '#c7c7c7' : '#707070')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) =>
        theme === 'light' ? 'unset' : 'difference'};
    }
  }

  ul {
    list-style: none;
    font-size: 15pt;
  }
`

export const Thumbnail = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 2rem;
  }

  img {
    width: 100%;
  }
`

export const AboutUs = styled.div`
  text-align: left;
  padding: 0rem 20rem;
  font-size: 15pt;

  @media (max-width: 768px) {
    padding: 0rem 5rem;
  }

  @media (max-width: 578px) {
    padding: 0rem 1.5rem;
    font-size: 12pt;
  }

  hr {
    background-color: #e6e6e6;
  }
`
