import contact from 'assets/illustrations/contact.svg'
import { Container } from 'components/common'
import { ThemeContext } from 'providers/ThemeProvider'
import React, { useContext } from 'react'
import ContactForm from './ContactForm'
import { Details, Thumbnail, Wrapper } from './styles'

export const Contact = () => {
  const { theme } = useContext(ThemeContext)
  return (
    <>
      <Wrapper as={Container} id='contact'>
        <Details theme={theme}>
          <ContactForm />
        </Details>
        <Thumbnail>
          <img src={contact} alt='People collaborating' />
        </Thumbnail>
      </Wrapper>
    </>
  )
}
