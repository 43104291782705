import React, { useState } from 'react'
import { Details, Message, Wrapper } from './styles'

function encode(data) {
  const formData = new FormData()

  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return formData
}

export default function ContactForm() {
  const [state, setState] = useState({})
  const [submit, setSubmit] = useState(false)

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => {
        setSubmit(true)
      })
      .catch((error) => alert(error))
  }

  return (
    <>
      <Wrapper>
        <Details>
          <h2>Tell us about your needs</h2>
          <form
            name='contact'
            method='post'
            action='/'
            data-netlify='true'
            data-netlify-honeypot='bot-field'
            onSubmit={handleSubmit}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type='hidden' name='form-name' value='file-upload' />
            <p hidden>
              <label>
                Don’t fill this out:{' '}
                <input name='bot-field' onChange={handleChange} />
              </label>
            </p>
            <p>
              <label>
                <input
                  type='text'
                  name='name'
                  onChange={handleChange}
                  placeholder='Name*'
                  required
                />
              </label>
            </p>{' '}
            <p>
              <label>
                <input
                  type='email'
                  name='email'
                  onChange={handleChange}
                  placeholder='Email*'
                  required
                />
              </label>
            </p>{' '}
            <p>
              <label>
                <textarea
                  name='message'
                  onChange={handleChange}
                  placeholder='Message*'
                  required
                ></textarea>
              </label>
            </p>
            <p>
              <button type='submit'>Send</button>
            </p>
            {submit ? (
              <Message>
                Message successfully sent! We will reach out to you soon!
              </Message>
            ) : null}
            <p>
              <p>Email: support@expresstranscriber.com</p>
              <p>Phone: +1 (801)-885-8377</p>
            </p>
          </form>
        </Details>
      </Wrapper>
    </>
  )
}
