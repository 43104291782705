import React, { useState } from 'react'

import { FlexContainer, Wrapper, FlexWrapper, BorderWrapper } from './styles'
import { Button } from 'components/common'

export const Process = () => {
  const [example, setExample] = useState(false)

  const toggleExample = () => {
    setExample(!example)
  }

  return (
    <Wrapper id='process'>
      <h1>How It Works</h1>
      <FlexWrapper>
        <FlexContainer>
          <h3>Meetings | Podcasts | Interviews</h3>
          <p>You can send us:</p>
          <ul>
            <li>A video or audio file or share a link that we can access.</li>
            <li>
              Desired format or a template for your transcripts. If you don’t
              have one, you can just tell us what you want. Otherwise we will
              give you text output that you can format.
            </li>
            <li>
              If speakers don't introduce themselves during the meeting, send us
              names of all speakers in the meeting.
            </li>
          </ul>
          <p>
            We do the rest and send you up to 4 hours of transcription within 24
            hours for your review (for recordings longer than 4 hours we will
            need more than 24 hours to process). If we made any mistakes, we
            will make necessary edits and make sure that your transcript is 100%
            correct.
          </p>
        </FlexContainer>
        <FlexContainer>
          <h3>Closed Captioning</h3>
          <ul>
            <li>Send us a video file or a link</li>
            <li>
              We will provide a .srt(SubRip Subtitle) file that you can upload
              to YouTube. If you are not uploading to YouTube, we will provide a
              close captioned video for you.
            </li>
            <li>
              <Button onClick={toggleExample}>
                See an example of .srt file
              </Button>
            </li>
            {example ? (
              <li>
                <BorderWrapper>
                  <div>
                    <p>1</p>
                    <p>
                      00:00:31,580 --&gt; 00:00:35,750{' '}
                      <span>(timestamp rounded to milliseconds)</span>
                    </p>
                    <p>Items listed on the agenda may</p>
                    <p>be taken out of order. Two or more</p>
                  </div>
                  <div style={{ marginTop: '1rem' }}>
                    <p>2</p>
                    <p>00:00:36,260 --&gt; 00:00:39,260</p>
                    <p>agenda items may be combined for</p>
                    <p>consideration.</p>
                  </div>
                </BorderWrapper>
              </li>
            ) : null}
          </ul>
        </FlexContainer>
      </FlexWrapper>
    </Wrapper>
  )
}
