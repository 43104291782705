import { Layout, SEO as Seo } from 'components/common'
import { Contact, Intro, Process, Skills } from 'components/landing'
import React from 'react'

const Index = () => {
  return (
    <Layout>
      <Seo />
      <Intro />
      <Skills />
      <Process />
      <Contact />
    </Layout>
  )
}

export default Index
